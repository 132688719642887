
.apDiv.SearchRequests #two{
    background-color: white;
    color: var(--main-color);
    backdrop-filter: "blur(5.5px)";
    border: "1px solid rgba( 255, 255, 255, 0.18 )";
}

.apDiv.order #three{
    background-color: white;
    color: var(--main-color);
    backdrop-filter: "blur(5.5px)";
    border: "1px solid rgba( 255, 255, 255, 0.18 )";
}
.search-requests-container {
    padding: 20px;
    background-color: #f9f9f9;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
}
 
.search-requests-container .filters-container {
    display: flex;
    align-items: center;
    /* justify-content: ; */
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
}
.search-requests-container .filters-container h1{
    margin-right: auto;
    color: var(--main-color);
}
@media (max-width: 980px) {
    .search-requests-container .filters-container {
            align-items: start;
            flex-direction: column;
    }
    .search-requests-container .filter input{
        width: 100%;
    }
    .search-requests-container a{
        flex-direction: column;
        align-items: start !important;
    }
    .date-range-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0px !important;
        width: 100%;
    }
  }

.search-requests-container .filter label {
    font-weight: bold;
    margin-bottom: 5px;
}
.date-range-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.date-range-container label{
    display: flex;
    flex-direction: column;
}
.search-requests-container .filter input,
.search-requests-container .filter select {
    padding: 10px;
    border-radius: 4px;
    background-color: rgba(76, 175, 79, 0.3);
    border: 1px solid #ccc;
    font-family: 'Cairo', sans-serif;
    transition: border-color 0.3s;
    width: 190px !important;
}
.search-requests-container .filter input:focus,
.search-requests-container .filter select:focus {
    border-color: rgba(76, 175, 79, 1);
    outline: none; 
}

.search-requests-container .cards-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.search-requests-container .request-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 15px;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;

}
.search-requests-container .request-card:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); 
}
.search-requests-container .request-card p {
    margin: 5px 0;
}
.search-requests-container .checkbox-container {
    display: flex;
    justify-content: start;
    cursor: pointer;
}
.search-requests-container a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: black;
    width: 95%;
}
.search-requests-container .custom-checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(76, 175, 79, 1);
    border-radius: 4px; 
    background-color: #fff; 
    position: relative; 
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.search-requests-container .custom-checkbox:checked {
    background-color: rgba(76, 175, 79, 1); 
    border-color: rgba(76, 175, 79, 1); 
}

.search-requests-container .custom-checkbox:checked::after {
    content: ""; 
    position: absolute;
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.modal-open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.modal-content {
    background-color: white !important;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 400px; 
    width: 100%; 
}
.div-open-modal{
    position: fixed;
    bottom: 20px;
    left: 50%; 
    width: 50%;
    transform: translateX(-50% );
    background-color: white;
    border: 1px solid rgba(151, 151, 151, 1) !important;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 100; 
}
.open-modal-button {
    color: white;
    background-color: var(--main-color);
    border: none;
    font-family: 'Cairo', sans-serif;
    padding: 5px 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.open-modal-button img{
    width: 20px;
}
.export-button{
    background-color: white;
    color: var(--main-color);
    border: 1px solid var(--main-color);
    padding: 5px 40px;
    border-radius: 5px;
    margin-left: 10px;
    font-family: 'Cairo', sans-serif;
    cursor: pointer;
}
.close-modal-button {
    background: white; 
    border: 1px solid rgba(255, 24, 53, 1);
    color: rgba(255, 24, 53, 1);
    border-radius: 5px;
    padding: 5px 20px;
    cursor: pointer;
    margin-top: 10px; 
    font-family: 'Cairo', sans-serif;
    cursor: pointer;
}
.filterDate label{
    display: flex;
    flex-direction: column;
}
.numberOrder h1{
    display: flex;
    justify-content: end;
    margin: 0px;
}